

/**
 * Facts
 * @class
 * @constructor
 * @public
 */
class ZeroMap {
  /**
   * Constructor
   * @returns {class}
   */
  constructor(  ) {
    // Engage engines
    this._init(  );
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  _init() {
    this._initMap();
    return this;
  }

  /**
   *
   * @returns {void}
   */
  _initMap() {
    /*console.log('init map');*/

    // Specify which endpoint to ping
    const endpoint = '/actions/google-maps/lookup/all';

    const location = document.getElementById('location');
    const searchForm = document.getElementById('locationSearch');
    const tokenField = document.getElementById('craftToken');
    const lat = document.getElementById('lat');
    const lng = document.getElementById('lng');
    let zoom = 10;
    const map = document.getElementById('partnerMap');

    /*https://plugins.doublesecretagency.com/google-maps/guides/ajax-geocoding-example/#step-1-the-form*/

    if (searchForm != null) {
      searchForm.addEventListener('submit', function(e) {
        e.preventDefault();
        let locationVal =  location.value;

        // Initialize data for the AJAX call
        let data = {};
        // Append CSRF Token to outgoing data
        data[tokenField.name] = tokenField.value;

        // Specify target for lookup (in this case, as a string)
        data['target'] = locationVal + ' CH';
        data['language'] = 'DE';

        fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => response.json())
          .then(data => {
            /*console.log('Success:', data);*/
            location.value = data.results[0].raw.formatted_address;
            let coords = {
              'lat': data.results[0].lat,
              'lng': data.results[0].lng
            }
            googleMaps.getMap('partnerMap').center(coords);
            googleMaps.getMap('partnerMap').zoom(12);

          })
          .catch((error) => {
            console.error('Error:', error);
          });

        location.blur();

        /*
        let geocoder = new google.maps.Geocoder();
        let map = googleMaps.getMap('partnerMap');

        geocoder.geocode( { 'address': locationVal  }, function(results, status) {
          if (status == 'OK') {
            console.log(results[0]);
            location.value = results[0].formatted_address;

              coords = {
                'lat': results[0].geometry.viewport.wb.lo,
                'lng': results[0].geometry.viewport.Ra.lo
              }

            map.center(coords);
            map.zoom(zoom);
          } else {
            location.value = '';
            map.zoom(10);
          }
        });*/
      });
    }
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'ZeroMap';
  }
};

module.exports = ZeroMap;
