

/**
 * ZeroPartners
 * @class
 * @constructor
 * @public
 */
class ZeroPartners {
  /**
   * Constructor
   * @param {string} name The container to handle
   * @returns {object} options The options
   * @returns {class}
   */
  constructor() {
    this.zeroPartners = document.getElementById('zeroPartners');

    // Engage engines
    if (this.zeroPartners) {
      this.init();
    }
  }
/*
Multiple KeyWords Query:
query getPartners {
  entries(section: "bobZeroPartnersPartners",  partnerKeywords: ["*hifi*", "*phone*"]) {
    ... on bobZeroPartnersPartners_bobZeroPartner_Entry {
      elementLinkURL
      title
      partnerKeywords
    }
  }
}
 */


  /**
   * Initialize the component
   * @returns {void}
   */
  init() {
    const that = this;

    that.btnLoadMore = document.getElementById('loadMore');
    that.btnkeywordSearch = document.getElementById('keywordSearch');
    that.btnReset = document.getElementById('btnReset');
    that.searchForm = document.getElementById('searchForm');

    const selectRegion = document.getElementById('zeroRegions');
    const selectCategory = document.getElementById('zeroCategories');
    const showAllBottom = document.getElementById('boxShowAllBottom');
    const showAllTop = document.getElementById('boxShowAllTop');

    let showAll = document.getElementsByClassName('boxShowAll');

    const limit = 9;
    const siteId = zeroPartners.dataset.site;
    const hasAd = zeroPartners.dataset.ad;

    let queryRegion = zeroPartners.dataset.region;
    let queryCategory = zeroPartners.dataset.category;
    that.offset = null;
    let queryUrl = null;

    that.lang = zeroPartners.dataset.lang;
    that.count = limit;
    that.totalCount = null;

    /* Check if we do have query params */
    if(queryRegion || queryCategory) {

      let selectedCategoryValue = null;
      let selectedRegionValue = null;


      let categoryOptions = selectCategory.options;
      let categories = [];
      let y = 0;

      while (y < categoryOptions.length) {
        categories.push(categoryOptions[y++].dataset.slug);
      }

      let regionOptions = selectRegion.options;
      let regions = [];
      let i = 0;

      while (i < regionOptions.length) {
        regions.push(regionOptions[i++].dataset.slug);
      }

      /* get value of Category from Querystring (slug) */
      if(categories.includes(queryCategory)) {
        selectedCategoryValue = document.querySelector('#zeroCategories option[data-slug="'+ queryCategory +'"]').value;
      }

      /* get value of Region from Querystring (slug) */
      if (regions.includes(queryRegion)) {
          selectedRegionValue = document.querySelector('#zeroRegions option[data-slug="'+ queryRegion +'"]').value;
      }

      let queryFilter = null;

      /* set query filter and set option */
      if(queryCategory && categories.includes(queryCategory) && queryRegion == '') {
        queryFilter = ',bobZeroCategories: '+ selectedCategoryValue;

        selectCategory.value = selectedCategoryValue;

        that.fetchBanner(selectedCategoryValue);

      } else if (queryRegion && regions.includes(queryRegion) && queryCategory == '') {
        queryFilter = ',bobZeroRegions:'+ selectedRegionValue;

        selectRegion.value = selectedRegionValue;

      } else if (queryCategory && categories.includes(queryCategory) && queryRegion && regions.includes(queryRegion)) {

        queryFilter = ',bobZeroCategories: '+ selectedCategoryValue +',bobZeroRegions:'+ selectedRegionValue;

        selectCategory.value = selectedCategoryValue;
        selectRegion.value = selectedRegionValue;

        that.fetchBanner(selectedCategoryValue);
      } else {
        queryFilter = '';
      }

      queryUrl = [
        '/api?query=',
        'query getPartners {',
        'entries(section: "bobZeroPartnersPartners", site: "'+ siteId +'", limit: '+ limit +', offset: 0',
        queryFilter,
        ') {',
        ' ... on bobZeroPartnersPartners_bobZeroPartner_Entry {',
        'id ',
        'title ',
        'elementLinkURL ',
        'elementText200 ',
        'partnerProducts ',
        'creditTerm ',
        'chooserContentLogo {url @transform (width: 200, height: 200, mode: "crop", position: "center-center")} ',
        'chooserImage {url @transform (width: 460, height: 320, mode: "crop", position: "center-center")} ',
        'partnerHighlight',
        '}',
        '}',
        'total: entryCount(section: "bobZeroPartnersPartners", site: "'+ siteId +'"',
        queryFilter,
        ')',
        '}'
      ].join('');

      this.fetchEntries(queryUrl);

    } else {
      let renderlimit;
      if (hasAd != '') {
        renderlimit = (limit -1);
      } else {
        renderlimit = limit;
      }

      queryUrl = [
        '/api?query=',
        'query getPartners {',
        'entries(section: "bobZeroPartnersPartners", site: "'+ siteId +'", limit: '+ renderlimit +', offset: 0,',
        ') {',
        ' ... on bobZeroPartnersPartners_bobZeroPartner_Entry {',
        'id ',
        'title ',
        'elementLinkURL ',
        'elementText200 ',
        'partnerProducts ',
        'creditTerm ',
        'chooserContentLogo {url @transform (width: 200, height: 200, mode: "crop", position: "center-center")} ',
        'chooserImage {url @transform (width: 460, height: 320, mode: "crop", position: "center-center")} ',
        'partnerHighlight',
        '}',
        '}',
        'total: entryCount(section: "bobZeroPartnersPartners", site: "'+ siteId +'"',
        ')',
        '}'
      ].join('');
      this.fetchEntries(queryUrl, hasAd);

    }

    selectCategory.addEventListener('change', function() {
      /*
      offset = null;
      */
      that.offset = null;
      let regionId = selectRegion.value;
      let categoryId = this.value;
      let categorySlug = this.options[this.selectedIndex].dataset.slug;
      let queryFilter = null;
      showAllTop.checked = false;

      document.getElementById('keyword').value='';

      if(categoryId && regionId == '') {
        queryFilter = ',bobZeroCategories: '+ categoryId;
        history.replaceState('Bob Zero', '', '?category=' + categorySlug);

      } else if (categoryId && regionId) {
        let regionSlug = selectRegion.options[selectRegion.selectedIndex].dataset.slug;
        queryFilter = ',bobZeroCategories: '+ categoryId +',bobZeroRegions:'+ regionId;
        history.replaceState('Bob Zero', '', '?category=' + categorySlug + '&region=' + regionSlug);
      }
        else if (regionId && categoryId == '') {
        let regionSlug = selectRegion.options[selectRegion.selectedIndex].dataset.slug;
        queryFilter = ',bobZeroRegions:'+ regionId;
        history.replaceState('Bob Zero', '', '?region=' + regionSlug);
      }
      else if (categoryId == '' && regionId == '') {
        history.replaceState('Bob Zero', '', '?');
      }

      queryUrl = [
        '/api?query=',
        'query getPartners {',
        'entries(section: "bobZeroPartnersPartners",site: "'+ siteId +'", limit: '+ limit +', offset: 0',
        queryFilter,
        ') {',
        ' ... on bobZeroPartnersPartners_bobZeroPartner_Entry {',
        'id ',
        'title ',
        'elementLinkURL ',
        'elementText200 ',
        'partnerProducts ',
        'creditTerm ',
        'chooserContentLogo {url @transform (width: 200, height: 200, mode: "crop", position: "center-center")} ',
        'chooserImage {url @transform (width: 460, height: 320, mode: "crop", position: "center-center")} ',
        'partnerHighlight',
        '}',
        '}',
        'total: entryCount(section: "bobZeroPartnersPartners", site: "'+ siteId +'"',
        queryFilter,
        ')',
        '}'
      ].join('');

      zeroPartners.classList.add('loading');
      that.fetchEntries(queryUrl);

      if(categoryId != '') {
        that.fetchBanner(categoryId);
      }
    });

    selectRegion.addEventListener('change', function() {
      /*offset = null;*/
      that.offset = null;
      let categoryId = selectCategory.value;
      let regionId = this.value;
      let regionSlug = this.options[this.selectedIndex].dataset.slug;
      let queryFilter = null;
      showAllTop.checked = false;

      document.getElementById('keyword').value='';

      if(categoryId && regionId == '') {
        let categorySlug = selectCategory.options[selectCategory.selectedIndex].dataset.slug;
        queryFilter = ',bobZeroCategories: '+ categoryId;
        history.replaceState('Bob Zero', '', '?category=' + categorySlug);
      } else if (categoryId && regionId) {
        let categorySlug = selectCategory.options[selectCategory.selectedIndex].dataset.slug;
        queryFilter = ',bobZeroCategories: '+ categoryId +',bobZeroRegions:'+ regionId;
        history.replaceState('Bob Zero', '', '?category=' + categorySlug + '&region=' + regionSlug);
      } else if (regionId && categoryId == '') {
        queryFilter = ',bobZeroRegions:'+ regionId;
        history.replaceState('Bob Zero', '', '?region=' + regionSlug);
      }  else if (categoryId == '' && regionId == '') {
        history.replaceState('Bob Zero', '', '?');
      }

      queryUrl = [
        '/api?query=',
        'query getPartners {',
        'entries(section: "bobZeroPartnersPartners", site: "'+ siteId +'", limit: '+ limit +', offset: 0',
        queryFilter,
        ') {',
        ' ... on bobZeroPartnersPartners_bobZeroPartner_Entry {',
        'id ',
        'title ',
        'elementLinkURL ',
        'elementText200 ',
        'partnerProducts ',
        'creditTerm ',
        'chooserContentLogo {url @transform (width: 200, height: 200, mode: "crop", position: "center-center")} ',
        'chooserImage {url @transform (width: 460, height: 320, mode: "crop", position: "center-center")} ',
        'partnerHighlight',
        '}',
        '}',
        'total: entryCount(section: "bobZeroPartnersPartners", site: "'+ siteId +'"',
        queryFilter,
        ')',
        '}'
      ].join('');
      zeroPartners.classList.add('loading');
      that.fetchEntries(queryUrl);
    });

    that.searchForm.addEventListener('submit', function(e) {
      e.preventDefault();
      // reset region and category
      let queryFilter = null;
      selectRegion.val = '';
      selectRegion.selectedIndex = 0;
      selectCategory.val = '';
      selectCategory.selectedIndex = 0;

      history.replaceState('Bob Zero', '', '');

      showAllTop.checked = false;

      zeroPartners.classList.add('loading');
      let val = document.getElementById('keyword').value;

      if (val.length > 1) {
        let searchString = val.split(' ');
        searchString = searchString.map(i => '"*' + i + '*"');
        queryFilter = ' partnerKeywords:  [' + searchString + ']';

      } else {
        queryFilter = '';
      }
      queryUrl = [
        '/api?query=',
        'query getPartners {',
        'entries(section: "bobZeroPartnersPartners", site: "'+ siteId +'", limit: '+ limit +', offset: 0,',
        queryFilter,
        ') {',
        ' ... on bobZeroPartnersPartners_bobZeroPartner_Entry {',
        'id ',
        'title ',
        'elementLinkURL ',
        'elementText200 ',
        'partnerProducts ',
        'creditTerm ',
        'chooserContentLogo {url @transform (width: 200, height: 200, mode: "crop", position: "center-center")} ',
        'chooserImage {url @transform (width: 460, height: 320, mode: "crop", position: "center-center")} ',
        'partnerHighlight',
        '}',
        '}',
        'total: entryCount(section: "bobZeroPartnersPartners", site: "'+ siteId +'"',
        queryFilter,
        ')',
        '}'
      ].join('');
      that.fetchEntries(queryUrl);
    });

    showAllTop.addEventListener('change', function() {
      if(showAllTop.checked) {
        showAllBottom.checked = true;
      }  else {
        showAllBottom.checked = false;
      }
      that.toggleAll(queryUrl);

    });
    showAllBottom.addEventListener('change', function() {
      if(showAllBottom.checked) {
        showAllTop.checked = true;
      }  else {
        showAllTop.checked = false;
      }
      that.toggleAll(queryUrl);
    });

    that.btnReset.addEventListener('click', function(e) {
      e.preventDefault();
      // resets keywords search
      document.getElementById('keyword').value='';
      // resets Show All
      showAllTop.checked = false;
      showAllBottom.checked = false;
      // resets Region and Category
      history.replaceState('Bob Zero', '', '?');
      selectRegion.val = '';
      selectRegion.selectedIndex = 0;
      selectCategory.val = '';
      selectCategory.selectedIndex = 0;

      let renderlimit;
      if (hasAd != '') {
        renderlimit = (limit -1);
      } else {
        renderlimit = limit;
      }

      queryUrl = [
        '/api?query=',
        'query getPartners {',
        'entries(section: "bobZeroPartnersPartners", site: "'+ siteId +'", limit: '+ renderlimit +', offset: 0,',
        ') {',
        ' ... on bobZeroPartnersPartners_bobZeroPartner_Entry {',
        'id ',
        'title ',
        'elementLinkURL ',
        'elementText200 ',
        'partnerProducts ',
        'creditTerm ',
        'chooserContentLogo {url @transform (width: 200, height: 200, mode: "crop", position: "center-center")} ',
        'chooserImage {url @transform (width: 460, height: 320, mode: "crop", position: "center-center")} ',
        'partnerHighlight',
        '}',
        '}',
        'total: entryCount(section: "bobZeroPartnersPartners", site: "'+ siteId +'"',
        ')',
        '}'
      ].join('');
      that.fetchEntries(queryUrl, hasAd);

    });

    that.btnLoadMore.addEventListener('click', function() {
      /* offset += limit; */
      that.offset += limit;
      that.count += limit;
      /*console.warn(that.offset);
      console.warn(that.totalCount);
      let tempQueryUrl = queryUrl.replace('offset: 0', 'offset: ' + offset);
      */
      let tempQueryUrl = queryUrl.replace('offset: 0', 'offset: ' + that.offset);

      tempQueryUrl = tempQueryUrl.replace('limit: 8', 'limit: ' + limit );
      that.appendfetchEntries(tempQueryUrl);
    });

    return this;
  }

  toggleAll(queryUrl) {

    const that = this;
    /* resets offset */
    that.offset = null;

    if (document.getElementById('boxShowAllTop').checked) {
      zeroPartners.classList.add('fast-animation');
      let tempQueryUrl = queryUrl.replace(/offset: \d,/gm, '' );
       tempQueryUrl = tempQueryUrl.replace(/limit: \d,/gm, '' );

      zeroPartners.classList.add('loading');
      that.fetchEntries(tempQueryUrl);

    } else {
      zeroPartners.classList.remove('fast-animation');
      let tempQueryUrl = queryUrl.replace(/offset: \d,/gm, 'offset: 0' );
      tempQueryUrl = tempQueryUrl.replace(/limit: \d,/gm, 'limit: 9' );

      zeroPartners.classList.add('loading');
      that.fetchEntries(tempQueryUrl);

      document.querySelector('.partner-list').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  fetchEntries(queryUrl, hasAd) {
    const that = this;
    fetch(queryUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then(r => r.json())
      .then(data => {
        that.renderEntries(data, hasAd);
        that.totalCount = data.data.total;
        that.checkCount();
      });
  }

  appendfetchEntries(tempQueryUrl) {
    const that = this;
    fetch(tempQueryUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then(r => r.json())
      .then(data => {
        that.appendEntries(data);
        /*console.log('append:', data);*/
        that.totalCount = data.data.total;
        that.checkCount();
      });
  }

  checkCount() {
    const that = this;
    if (that.count >= that.totalCount) {
      that.btnLoadMore.classList.add('hidden');
    } else {
      that.btnLoadMore.classList.remove('hidden');
    }
  }

  renderEntries(partners, hasAd) {
    const that = this;
    that.count = partners.data.entries.length;
    zeroPartners.innerHTML = '';
    if(partners.data.entries.length == '') {
      zeroPartners.innerHTML = '<p class="no-partners">' + zeroPartners.dataset.noentry + '</p>';
      zeroPartners.classList.remove('loading');
    }

    for(let i = 0; i < partners.data.entries.length; i++) {
      if (hasAd && i === 5) {
        let ad = document.createElement('div');
        ad.classList.add('col-xs-12');
        ad.classList.add('col-md-4');

        ad.innerHTML = hasAd;
        zeroPartners.appendChild(ad);
        setTimeout(()=> ad.classList.add('animate'), i*200);
      }
      let entry = partners.data.entries[i];

      //check if entries have values - matrix fields with assets 'bug'
      if(!(Object.entries(entry).length === 0 && entry.constructor === Object)) {
        let partner= document.createElement('div');

        partner.classList.add('col-xs-12');
        partner.classList.add('col-md-4');
        let altText = null;
        if(entry.elementText200 != '') {
          altText = entry.elementText200;
        } else {
          altText = entry.title;
        }
        let partnerProducts = '';
        let creditTerm = '';
        let partnerProductsLabel = '';
        let creditTermLabel = '';
        let showEntryInfo = 'hidden';

        if (entry.partnerProducts != null) {
          partnerProductsLabel = '<span class="text--size-tiny">'+zeroPartners.dataset.productslabel+'</span>';
          partnerProducts = '<p>' + entry.partnerProducts + '</p>';
        }

        if (entry.creditTerm != null) {
          creditTermLabel = '<span class="text--size-tiny">'+zeroPartners.dataset.credittermlabel+'</span>',
          creditTerm = '<p>' + entry.creditTerm + '</p>';
        }

        if (entry.creditTerm != null || entry.partnerProducts != null) {
          showEntryInfo = '';
        }

        let partnerContent = [
          '<a href="' + entry.elementLinkURL + '" target="_blank" class="badge badge--'+ entry.partnerHighlight +' '+ that.lang + ' ">',
          '<img src="' + entry.chooserImage[0].url + '" alt="'+ entry.elementText200 +'" class="mood info-'+showEntryInfo+'"/>',
          '<img class="logo info-'+showEntryInfo+'" src="' + entry.chooserContentLogo[0].url + '" alt="'+ entry.title +'"/>',
          '<span class="info '+ showEntryInfo +'"><div class="info-wrapper">',
          partnerProductsLabel,
          partnerProducts,
          creditTermLabel,
          creditTerm,
          '<button class="button button--small">'+ zeroPartners.dataset.btnlabel +'</button>',
          '</div></span>',
          '</a>'
        ].join('');

        partner.innerHTML = partnerContent;

        zeroPartners.appendChild(partner);

        setTimeout(()=> partner.classList.add('animate'), i*200);

        zeroPartners.classList.remove('loading');
      }
    }
  }

  appendEntries(partners) {
    const that = this;
    zeroPartners.classList.add('loading');

    if(partners.data.entries.length == '') {
      zeroPartners.innerHTML = zeroPartners.dataset.noentry;
    }

    for(let i = 0; i < partners.data.entries.length; i++) {
      let entry = partners.data.entries[i];

      //check if entries have values - matrix fields with assets 'bug'
      if(!(Object.entries(entry).length === 0 && entry.constructor === Object)) {
        let partner= document.createElement('div');
        partner.classList.add('col-xs-12');
        partner.classList.add('col-md-4');

        let partnerProducts = '';
        let creditTerm = '';
        let partnerProductsLabel = '';
        let creditTermLabel = '';
        let showEntryInfo = 'hidden';

        if (entry.partnerProducts != null) {
          partnerProductsLabel = '<span class="text--size-tiny">'+zeroPartners.dataset.productslabel+'</span>';
          partnerProducts = '<p>' + entry.partnerProducts + '</p>';
        }

        if (entry.creditTerm != null) {
          creditTermLabel = '<span class="text--size-tiny">'+zeroPartners.dataset.credittermlabel+'</span>',
            creditTerm = '<p>' + entry.creditTerm + '</p>';
        }

        if (entry.creditTerm != null || entry.partnerProducts != null) {
          showEntryInfo = '';
        }

        let partnerContent = [
          '<a href="' + entry.elementLinkURL + '" target="_blank" class="badge badge--'+ entry.partnerHighlight +' '+ that.lang + '">',
          '<img src="' + entry.chooserImage[0].url + '" alt="'+ entry.elementText200 +'" class="mood info-'+showEntryInfo+'"/>',
          '<img class="logo info-'+showEntryInfo+'" src="' + entry.chooserContentLogo[0].url + '" alt="'+ entry.title +'"/>',
          '<span class="info '+ showEntryInfo +'"><div class="info-wrapper">',
          partnerProductsLabel,
          partnerProducts,
          creditTermLabel,
          creditTerm,
          '<button class="button button--small">'+ zeroPartners.dataset.btnlabel +'</button>',
          '</div></span>',
          '</a>'
        ].join('');

        partner.innerHTML = partnerContent;

        zeroPartners.appendChild(partner);
        setTimeout(()=> partner.classList.add('animate'), i*500);

        zeroPartners.classList.remove('loading');
      }
    }
  }

  fetchBanner(category) {
    /*console.warn(category)*/
    let bannerQueryUrl = [
      '/api?query=',
      'query getBanner {',
      'entries(section: "bobZeroBanners", bobZeroCategories:'+ category +'  ) {',
      '... on bobZeroBanners_bobZeroBanners_Entry {',
      'elementLinkURL ',
      'elementText200 ',
      'bobZeroDesktopImageChooser {url @transform (width: 960, height: 300, mode: "crop", position: "center-center")} ',
      'bobZeroMobileImageChooser {url @transform (width: 334, height: 300, mode: "crop", position: "center-center") }',
      '}',
      '}',
      '}'
    ].join('');

        const that = this;
        fetch(bannerQueryUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        })
          .then(r => r.json())
          .then(data => {
            //console.log(data);
            that.renderBanner(data);
          });
  }

  renderBanner(bannerData) {
    /*console.warn(bannerData);*/
    let zeroBanner = document.getElementById('zeroBanner');

    if(bannerData.data.entries.length != '') {

      document.getElementById('zeroBanner').innerHTML = '';

      let banner = document.createElement('div');
      banner.classList.add('col-xs-12');
      banner.classList.add('col-md-12');

      let bannerContent = [
        '<a href="'+ bannerData.data.entries[0].elementLinkURL +'" target="_blank">',
        '<picture>',
        '<source media="(min-width: 600px)" srcset="'+ bannerData.data.entries[0].bobZeroDesktopImageChooser[0].url+'">',
        '<img srcset="'+bannerData.data.entries[0].bobZeroMobileImageChooser[0].url+'" alt="'+ bannerData.data.entries[0].elementText200 +'" title="'+ bannerData.data.entries[0].elementText200 +'">',
        '</picture>',
        '</a>'
      ].join('');

      banner.innerHTML = bannerContent;

      document.getElementById('zeroBanner').appendChild(banner);
    }
  }

  /**
   * Class name
   * @returns {string} The human-readable class name
   */
  get name() {
    return 'ZeroPartners';
  }
};

module.exports = ZeroPartners;

/* Example Query of KeyWord, Category and Region */
/*
query getPartners {
  entries(section: "bobZeroPartnersPartners", partnerKeywords: "*hifi*",bobZeroCategories: 415602, bobZeroRegions:239535) {
    ... on bobZeroPartnersPartners_bobZeroPartner_Entry {
      id
      elementLinkURL
      title
      partnerHighlight
      chooserImage {
        url @transform (width: 960, height: 300, mode: "crop", position: "center-center")
      }
    }
  }
  total: entryCount(section: "bobZeroPartnersPartners", partnerKeywords: "*hifi*", bobZeroCategories: 415602, bobZeroRegions:239535)
}
* */
