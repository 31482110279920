import Utils from '../../../scripts/vendors/utils';
import OverlayManager from '../../elements/overlayManager';

/**
 * CookieHint
 * @class
 * @constructor
 * @public
 */
class CookieHint {
  /**
   * Constructor
   * @returns {class}
   */
  constructor() {
    if (!app.cookies) {
      return;
    }

    // Engage engines
    this.init();
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {
    // console.log(`${app.cookies.cookieHint.name} cookie loaded with value ${Utils.cookie.get(app.cookies.cookieHint.name)}`);

    this._overlayManager = new OverlayManager();

    if (app.cookies.cookieHint && !Utils.cookie.get(app.cookies.cookieHint.name)) {
      Utils.cookie.accepted = false;

      const cookieHint = document.getElementById('cookie-hint--js');
      const cookieButton = document.getElementById('cookie-button');

      if (cookieHint && cookieButton) {
        cookieHint.classList.add('is-active');
        document.body.classList.add('overlay--prevent-bodyscrolling');

        this._overlayManager.disableBodyScrolling();

        cookieButton.addEventListener('click', (event) => {
          event.preventDefault();
          this._overlayManager.enableBodyScrolling();
          Utils.cookie.accepted = true;
          Utils.cookie.set(app.cookies.cookieHint.name, true, app.cookies.cookieHint.expires, app.cookies.cookieHint.path);
          cookieHint.classList.remove('is-active');
        });
      }
    }

    return this;
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'CookieHint';
  }
};

module.exports = CookieHint;
