

/**
 * Facts
 * @class
 * @constructor
 * @public
 */
class Facts {
  /**
   * Constructor
   * @returns {class}
   */
  constructor(  ) {
    // Engage engines
    this._init(  );
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  _init() {
    this._initFacts();
    return this;
  }

  /**
   *
   * @returns {void}
   */
  _initFacts() {
    let factsModule = document.getElementById('facts');

    if (factsModule != null) {
      factsModule.addEventListener('transitionend', () => {
        const counters = document.querySelectorAll('.factsValue');
        const speed = 2000;

        counters.forEach( counter => {
          const animate = () => {
            const value = +counter.getAttribute('data-count');
            const data = +counter.innerText;

            const time = value / speed;
            if(data < value) {
              counter.innerText = Math.ceil(data + time);
              setTimeout(animate, 1);
            } else {
              counter.innerText = value;
            }
          }
          animate();
        });
      });
    }
  }

  /**
   * Class name
   * @returns {string} The human readable class name
   */
  get name() {
    return 'Facts';
  }
};

module.exports = Facts;
